/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { colors } from '../consts/style';

import Socials from './Socials';
import { Container } from './Elements';
import mq from '../style/mq';

export const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.2rem;
  p,
  a {
    font-size: 1.4rem;
    color: ${colors.gold};
  }
  a {
    &:hover {
      color: ${colors.gold};
    }
  }
`;

const FollowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  iframe {
    border: none;
    overflow: hidden;
    margin-bottom: 2rem;
    margin-left: 12rem;
    ${mq.mobile`
    margin-left: 0;
    width: 18rem;
    `}
  }
`;

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <Wrapper>
      <FollowWrapper>
        <iframe
          title="Spotify follow button"
          src="https://open.spotify.com/follow/1/?uri=spotify:artist:4LoM3lMauQ540MW9QiyIcR&size=detail&theme=light"
          width="300"
          height="56"
          scrolling="no"
          frameBorder="0"
          allowTransparency="true"
        />
      </FollowWrapper>
      <Socials />
      <p>Copyright &copy; Anna Nalick {year}</p>
    </Wrapper>
  );
};

export default Footer;
