import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Container, Inner } from '../components/Elements';
import scrollToElement from 'scroll-to-element';
import { useScrollYPosition } from 'react-use-scroll-position';
import useWindowHeight from '../hooks/useWindowHeight';

import { colors, font, offset, duration } from '../consts/style';
import mq from '../style/mq';

import Logo from './Icons/Logo';
import Open from './Icons/Open';
import Close from './Icons/Close';

const Wrapper = styled.div`
  height: 6rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2000;
`;

const HeaderInner = styled(Inner)`
  display: flex;
  height: 6rem;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 200;
  max-width: 100%;
`;

const ExternalNavItem = styled.a`
  margin-right: 3.2rem;
  font-weight: bold;
  ${font.nav}
  &:hover {
    color: ${colors.light};
    text-decoration: none;
  }
  &:last-child {
    margin: 0;
  }
`;

const NavItem = styled(Link)`
  margin-right: 3.2rem;
  font-weight: bold;
  ${font.nav}
  &:hover {
    color: ${colors.light};
    text-decoration: none;
  }
  &:last-child {
    margin: 0;
  }
`;

const Nav = styled.nav`
  ${mq.tablet`
    display: none;
    `}
  ${NavItem}, ${ExternalNavItem} {
    transition: color 0.5s ease;
    color: ${props => (props.dark ? colors.gold : colors.light)};
  }
`;

const MenuButton = styled.button`
  width: 3rem;
  background: transparent;
  border: none;
  padding: 0;
  img {
    max-width: 100%;
  }
  ${mq.tablet_up`
    display: none;
  `}
`;

const MobileMenu = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  height: ${props => (props.open ? '100%' : 0)};
  width: 100%;
  background: ${colors.light};
  z-index: 4000;
  transition: height 0.5s ease;
  overflow: hidden;
  ${Container} {
    min-height: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -2rem;
    ${NavItem}, ${ExternalNavItem} {
      opacity: ${props => (props.open ? 1 : 0)};
      position: relative;
      top: ${props => (props.open ? '3.2rem' : 0)};
      transition: opacity 0.5s ease, top 0.5s ease;
      transition-delay: 0.5s;
      margin-right: 0;
      margin-bottom: 1.2rem;
      color: ${colors.gold};
      ${font.h1};
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 2rem;
  right: 2rem;
  height: 4.4rem;
  width: 4.4rem;
  opacity: ${props => (props.shown ? 1 : 0)};
  cursor: pointer;
  z-index: 5000;
  border: none;
  background: transparent;
  transition: opacity 0.5s ease;
  transition-delay: 0.5s;
  img,
  svg {
    width: 2.8rem !important;
  }
`;

const ScrollNav = styled(Container)`
  position: fixed;
  top: ${props => (!props.shown ? '-4.4rem' : 0)};
  left: 0;
  width: 100%;
  z-index: 4000;
  background: ${colors.gold};
  height: 4.4rem;
  transition: top 0.5s ease;
  align-items: center;
  justify-content: center;
  display: flex;
  ${mq.mobile`
  display: none;
  `}
   ${NavItem}, ${ExternalNavItem} {
    color: ${colors.light} !important;
  }
`;

function Header({ location }) {
  const [open, setOpen] = useState(false);
  const [dark, setDark] = useState(false);
  const scrollY = useScrollYPosition();
  const windowHeight = useWindowHeight();
  const isHome = location.pathname === '/';
  const isAbout = location.pathname === '/about';
  const showScrollNav = scrollY >= (isHome ? windowHeight : 120);
  const hideScrollNav = isAbout;

  const handleLinkClick = (e, target) => {
    if (isHome) {
      e.preventDefault();
      setOpen(false);
      scrollToElement(target, {
        offset: target === '#hero-section' ? 0 : offset,
        duration,
      });
    }
  };

  useEffect(() => {
    setOpen(false);
    setDark(location ? location.pathname !== '/' : true);
  }, [location]);

  const renderNavItems = () => {
    return (
      <Fragment>
        <NavItem to="/" onClick={e => handleLinkClick(e, '#top')} title="Home">
          Home
        </NavItem>
        <NavItem
          to="/#music"
          onClick={e => handleLinkClick(e, '#music')}
          title="Music"
        >
          Music
        </NavItem>
        <NavItem
          to="/#tour"
          onClick={e => handleLinkClick(e, '#tour')}
          title="Tour"
        >
          Tour
        </NavItem>

        <NavItem to="/about" title="About">
          About
        </NavItem>
        <NavItem to="/store" title="Store">
          Store
        </NavItem>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {!hideScrollNav && (
        <ScrollNav shown={showScrollNav}>{renderNavItems()}</ScrollNav>
      )}
      <Wrapper>
        <Container>
          <HeaderInner>
            <Link to="/">
              <Logo dark={dark} />
            </Link>
            <MenuButton onClick={() => setOpen(!open)}>
              <Open dark={dark} />
            </MenuButton>
            <Nav dark={dark}>{renderNavItems()}</Nav>
          </HeaderInner>
        </Container>
      </Wrapper>
      <MobileMenu open={open}>
        <CloseButton shown={open} onClick={() => setOpen(false)}>
          <Close />
        </CloseButton>
        <Container>{renderNavItems()}</Container>
      </MobileMenu>
    </Fragment>
  );
}

Header.propTypes = {
  location: PropTypes.object,
};

export default Header;
