import bp from './breakpoints';

const mq = {
  mobile: `${bp.mobile}px`,
  tablet: `${bp.tablet}px`,
  desktop: `${bp.desktop}px`,
};

export const ellipsis = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const colors = {
  dark: '#222',
  light: '#FFF',
  purple: '#9135AD',
  gold: '#977F46',
  goldTint: '#907841',
};

export const font = {
  h1: `
        font-size: 4rem;
        line-height: 5.6rem;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: .2rem;
        @media screen and (max-width: ${mq.tablet}) {
            font-size: 3rem;
        }
    `,
  h2: `
        font-size: 2.8rem;
        line-height: 4rem;
        margin: 0;
        text-transform: uppercase;
        letter-spacing: .2rem;
        @media screen and (max-width: ${mq.tablet}) {
            line-height: 3.6rem;
            font-size: 2.4rem;
        }
    `,
  h3: `
        font-size: 2rem;
        font-weight: bold;
        line-height: 3.4rem;
        margin: 0;
        text-transform: uppercase;
        letter-spacing: .2rem;
    `,
  p: `
        font-size: 1.6rem;
        line-height: 2.4rem;
    `,
  nav: `
        font-size: 1.2rem;
        line-height: 2.4rem;
        text-transform: uppercase;
        letter-spacing: .12rem;
  `,
  button: `
        transition: all .3s ease;
        font-size: 1.4rem;
        line-height: 2.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.6rem;
        background: ${colors.gold};
        border: .2rem solid ${colors.goldTint};
        color: ${colors.light};
        text-transform: uppercase;
        border-radius: .2rem;
        font-weight: bold;
        letter-spacing: .2rem;
        padding: 0 2rem;
        // span {
        //   position: relative;
        //   top: -.1rem;
        // }
        &:hover {
          background: ${colors.goldTint};
        }
        &:active, &:focus {
          outline: none;
        }
        &.disabled {
          cursor: not-allowed;
          opacity: .5;
        }
  `,
  buttonBlack: `
        transition: all .3s ease;
        font-size: 1.4rem;
        line-height: 2.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.6rem;
        background: transparent;
        border: .2rem solid white;
        color: ${colors.light};
        text-transform: uppercase;
        border-radius: .2rem;
        font-weight: bold;
        letter-spacing: .2rem;
        padding: 0 2rem;
        // span {
        //   position: relative;
        //   top: -.1rem;
        // }
        &:hover {
          background: white; 
          color: black;
        }
        &:active, &:focus {
          outline: none;
        }
  `,
};

export const duration = 1000;
export const offset = -60;
