import styled from 'styled-components';

import mq from '../style/mq';

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 0 6rem;
  box-sizing: border-box;
  ${mq.tablet`
  padding: 0 4rem;
  `}
  ${mq.mobile`
  padding: 0 2rem;
  `}
`;

export const Inner = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
`;
