import 'typeface-lato';
import { createGlobalStyle } from 'styled-components';

const Global = createGlobalStyle`

html, body { font-family: "Lato", sans-serif;} 

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:hover {
  text-decoration: none;
}

h2 {
  font-weight: bold;
}

`;

export default Global;
