import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;

  a {
    margin-right: 1.2rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;
const Image = styled(Img)`
  width: 2.4rem;
  opacity: 1;
  transition: opacity 0.5s ease;
`;

const socialQuery = graphql`
  {
    social: datoCmsGeneral {
      facebookLink
      instagramLink
      twitterLink
      bandsintownLink
      youtubeLink
      soundcloudLink
    }
    fb: file(relativePath: { eq: "facebook.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    ig: file(relativePath: { eq: "instagram.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    yt: file(relativePath: { eq: "youtube.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    tw: file(relativePath: { eq: "twitter.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    bt: file(relativePath: { eq: "bandsintown.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    sc: file(relativePath: { eq: "soundcloud.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default function Socials() {
  const data = useStaticQuery(socialQuery);
  const {
    facebookLink,
    instagramLink,
    twitterLink,
    bandsintownLink,
    youtubeLink,
    soundcloudLink,
  } = data.social;
  const { fb, tw, ig, yt, bt, sc } = data;
  return (
    <Wrapper>
      {facebookLink && (
        <a
          href={facebookLink}
          target="blank"
          rel="nofollow noopener noreferrer"
        >
          <Image fluid={fb.childImageSharp.fluid} />
        </a>
      )}
      {instagramLink && (
        <a
          href={instagramLink}
          target="blank"
          rel="nofollow noopener noreferrer"
        >
          <Image fluid={ig.childImageSharp.fluid} />
        </a>
      )}
      {twitterLink && (
        <a href={twitterLink} target="blank" rel="nofollow noopener noreferrer">
          <Image fluid={tw.childImageSharp.fluid} />
        </a>
      )}
      {youtubeLink && (
        <a href={youtubeLink} target="blank" rel="nofollow noopener noreferrer">
          <Image fluid={yt.childImageSharp.fluid} />
        </a>
      )}
      {bandsintownLink && (
        <a
          href={bandsintownLink}
          target="blank"
          rel="nofollow noopener noreferrer"
        >
          <Image fluid={bt.childImageSharp.fluid} />
        </a>
      )}
      {soundcloudLink && (
        <a
          href={soundcloudLink}
          target="blank"
          rel="nofollow noopener noreferrer"
        >
          <Image fluid={sc.childImageSharp.fluid} />
        </a>
      )}
    </Wrapper>
  );
}
