/* eslint-disable quotes */
import React from 'react';
import { ModalProvider } from './src/store/modalContext';
const scrollToElement = require('scroll-to-element');

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return <ModalProvider>{element}</ModalProvider>;
};

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`);
  }
};

export const onRouteUpdate = ({ location }) => {
  checkHash(location);
};

const checkHash = location => {
  let { hash } = location;
  if (hash) {
    scrollToElement(hash, {
      offset: -90,
      duration: 1000,
    });
  }
};
