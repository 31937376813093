import React from 'react';
import styled from 'styled-components';

import { colors } from '../../consts/style';

const Svg = styled.svg`
  fill: ${colors.gold};
`;

export default function Close() {
  return (
    <Svg
      enable-background="new 0 0 413.348 413.348"
      viewBox="0 0 413.348 413.348"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z" />
    </Svg>
  );
}
